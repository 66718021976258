import React from 'react';
import PaulImage from '../assets/img/paul-meise.jpg'
import PaulLogo from '../assets/img/paulMeise-logo.jpg'
import Layout from '../components/LayoutPaulMeise';

const IndexPage = () => (
  <Layout>
    <div id="mainContainer">
      <h1><span className='cap'>P</span>aul <span className='cap'>M</span>eise, ABR GRI</h1>
      <aside className="info">
      <section className="photo">
        <figure>
          <img src={PaulImage} alt="Paul Meise" />
        </figure>
        <figure>
          <img src={PaulLogo} alt="Creed Realty logo" />
          <figcaption>Licensed in Virginia</figcaption>
        </figure>
      </section>
       <section className="contact">
        <address>
          600 Lynnhaven Parkway<br/>
          Suite 206<br/>
          Virginia Beach, VA 23452<br/>
        </address>
        <div className="telephone">
          Cell: <a href="tel:757-472-5454" className="tel">(757) 472-5454</a><br />

          <a href="mailto:paulmeise1983@gmail.com" className="emaillink" id="email" title="Click to e-mail Paul.">paulmeise1983@gmail.com</a><br />
          <a href="https://www.facebook.com/Paul-Meise-Realtor-1837374523189846/" className="home button button-primary" title="Click to learn more about Paul." target="_parent">Click for more information</a>
        </div>
        </section>
      </aside>
    </div>
  </Layout>
);

export default IndexPage;
